// extracted by mini-css-extract-plugin
export var background = "HeroSection-module--background--25687";
export var content = "HeroSection-module--content--472b9";
export var ctaButton = "HeroSection-module--ctaButton--870d4";
export var inner = "HeroSection-module--inner--de5da";
export var slideInBottom = "HeroSection-module--slideInBottom--f487d";
export var slideInRight = "HeroSection-module--slideInRight--73719";
export var socialIcons = "HeroSection-module--socialIcons--5c361";
export var subtitle = "HeroSection-module--subtitle--10006";
export var textArea = "HeroSection-module--textArea--e1474";
export var title = "HeroSection-module--title--8514b";
export var top = "HeroSection-module--top--fbdf5";