import React from 'react';
import * as styles from './HeroSection.module.css';

/**
 * HeroSection コンポーネント
 *
 * このコンポーネントは背景画像とお問い合わせリンクを含むヒーローセクションを描画します。
 * 背景画像のパスとリンクURLは、プロパティを使用してカスタマイズ可能です。
 *
 * @param {string} imagePath - 背景画像のパス。
 * @param {string} ctrLink - お問い合わせボタンのリンク先URL。
 * @returns {JSX.Element} レンダリングされたヒーローセクション。
 */
type HeroSectionProps = {
  imagePath: string;
  ctaLink: string;
  buttonText: string;
};

const HeroSection: React.FC<HeroSectionProps> = ({
  imagePath,
  ctaLink,
  buttonText,
}) => {
  return (
    <div className={styles.top}>
      <div className={styles.background}>
        <img src={imagePath} alt="背景画像" />
      </div>
      <div className={styles.content}>
        <div className={styles.inner}>
          <div className={styles.textArea}>
            <a href={ctaLink} className={styles.ctaButton}>
              {buttonText}
            </a>
          </div>
          <div className={styles.socialIcons}></div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
